import { apiBaseUrl } from '../conf';

import type { RawMeasurement } from '../types/entities';

const readingEventSource = new EventSource(`${apiBaseUrl}/measurements/event-stream`);

const handleEvent = (ev: MessageEvent<string>) => {
    const rawMeasurement: RawMeasurement = JSON.parse(ev.data);
    dispatchEvent(new CustomEvent<RawMeasurement>('measurementEvent', { detail: rawMeasurement }));
};

const listen = () => (readingEventSource.onmessage = handleEvent);
const close = () => readingEventSource.close;

export const sse = { listen, close };
