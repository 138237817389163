import { format, intervalToDuration } from 'date-fns';

export const dateStr = (date: Date) => format(date, 'y-MM-dd');
export const timeStr = (date: Date) => format(date, 'HH:mm:ss');
export const datetimeStr = (date: Date) => format(date, 'y-MM-dd HH:mm:ss');

export const elapsedTimeStr = (start: Date, end: Date) => {
    const duration = intervalToDuration({ start, end });
    let [years, months, days] = ['', '', ''];

    if (duration.years) {
        years = `${duration.years} year${duration.years > 1 ? 's' : ''}`;
    }
    if (duration.months) {
        months = `${duration.months} month${duration.months > 1 ? 's' : ''}`;
    }
    if (duration.days) {
        days = `${duration.days} day${duration.days > 1 ? 's' : ''}`;
    }

    const response = [years, months, days].filter(Boolean);

    switch (response.length) {
        case 3:
            response[1] += ' and';
            response[0] += ',';
            break;
        case 2:
            response[0] += ' and';
            break;
    }

    return response.join(' ');
};
