import { EUnit } from '../types/enums';

import type { EField } from '../types/enums';

export const fieldUnitMapping: Record<EField, EUnit> = {
    humidity: EUnit.percent,
    temperature: EUnit.celsius,
    pressure: EUnit.hpa,
    accel: EUnit.ms2,
    accel_x: EUnit.ms2,
    accel_y: EUnit.ms2,
    accel_z: EUnit.ms2,
    battery: EUnit.volt,
    rssi: EUnit.dbm,
};

export const fieldColorMapping: Record<EField, string> = {
    humidity: '#28f',
    temperature: '#fa0',
    pressure: '#f75',
    accel: '#f00',
    accel_x: '#77f',
    accel_y: '#f7c',
    accel_z: '#5ce',
    battery: '#0c0',
    rssi: '#c0f',
};

export const unitLabel: Record<EUnit, string> = {
    percent: '%',
    celsius: '°C',
    hpa: 'hPa',
    ms2: 'm/s²',
    dbm: 'dBm',
    volt: 'V',
};

type CompareStringInput = string | null | undefined;

export const stringSortFn = (a: CompareStringInput, b: CompareStringInput, numeric = true) => {
    const comparison = (a ?? '').localeCompare(b ?? '', 'fr', { numeric });

    if (comparison < 0) {
        return -1;
    } else if (comparison > 0) {
        return 1;
    }

    return 0;
};

export const sortBy = <T, G extends (array: T) => CompareStringInput>(array: T[], getter: G, numeric = true) => {
    return [...array].sort((a, b) => stringSortFn(getter(a), getter(b), numeric));
};
