import { Skeleton } from 'primereact/skeleton';
import { Fragment, useCallback } from 'react';
import { EField } from '../types/enums';
import { fieldUnitMapping, unitLabel } from '../utils/entityUtils';
import { formatNumber } from '../utils/stringUtils';

import type { Device, Measurement } from '../types/entities';

type Props = {
    device: Device;
    measurement?: Measurement;
};

export default function MeasurementSummary({ device, measurement }: Props) {
    const fieldTemplate = useCallback(
        (measurement: Measurement, field: EField) => (
            <div key={field} className='flex align-items-center justify-content-end' style={{ gap: '0.25rem' }}>
                <div>{formatNumber(measurement[field] ?? 0, null)}</div>
                <div>{unitLabel[fieldUnitMapping[field]]}</div>
            </div>
        ),
        [],
    );

    return (
        <>
            <div style={{ fontFamily: 'monospace', marginRight: '-0.2rem' }}>[{device.display_name[0]}]</div>
            {measurement ? (
                [EField.temperature, EField.humidity, EField.battery].map(field => fieldTemplate(measurement, field))
            ) : (
                <Fragment key={device.id}>
                    <Skeleton width='4rem' height='1rem' />
                    <Skeleton width='4rem' height='1rem' />
                    <Skeleton width='4rem' height='1rem' />
                </Fragment>
            )}
        </>
    );
}
