import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { sse } from './api/sse';

export default function Root() {
    // open sse connection
    useEffect(() => {
        sse.listen();

        return () => {
            sse.close();
        };
    }, []);

    return <Outlet />;
}
