import { fieldColorMapping, fieldUnitMapping, unitLabel } from './entityUtils';
import { tooltipHtml } from './templates';

import type { Device, Measurement } from '../types/entities';
import type { EField, EUnit } from '../types/enums';
import type { TooltipCallbackParams } from './templates';
import type { EChartsOption, LegendComponentOption, Payload, SeriesOption } from 'echarts';
import type { GridOption, YAXisOption } from 'echarts/types/dist/shared';
import type { ValueAxisBaseOption } from 'echarts/types/src/coord/axisCommonTypes.js';

export const loadingOptions: object = {
    color: '#aaa',
    maskColor: '#111a',
    text: '',
    spinnerRadius: 18,
    lineWidth: 2,
};

export const resetZoomAction: Payload = {
    type: 'dataZoom',
    batch: [
        {
            start: 0,
            end: 100,
            startValue: undefined,
            endValue: undefined,
        },
    ],
};

export const baseYAxisOption: YAXisOption[] = [{ type: 'value' }];
export const baseGridOption: GridOption = {
    top: 75,
    right: 10,
    bottom: 25,
    left: 0,
};

export const baseChartOption: EChartsOption = {
    backgroundColor: 'transparent',
    toolbox: { feature: { dataZoom: { yAxisIndex: 'none' } } },
    grid: baseGridOption,
    tooltip: {
        trigger: 'axis',
        backgroundColor: '#000c',
        borderColor: '#ccc5',
        formatter: (params: unknown) => tooltipHtml(params as TooltipCallbackParams[]),
        axisPointer: {
            type: 'cross',
            label: {
                color: '#abc',
                backgroundColor: '#000',
                fontSize: 12,
                padding: 4,
                margin: 4,
            },
            lineStyle: { color: '#eee5' },
            crossStyle: { color: '#444' },
        },
    },
    xAxis: {
        id: 'xAxis',
        type: 'time',
        axisTick: { length: 3 },
        axisLine: { lineStyle: { color: '#444' } },
        axisLabel: { fontSize: 12, color: '#ddd' },
    },
    yAxis: baseYAxisOption,
    emphasis: { focus: 'series' },
    textStyle: { fontSize: 12 },
};

const unitScale: Record<EUnit, ValueAxisBaseOption['scale']> = {
    percent: false,
    celsius: false,
    hpa: true,
    ms2: true,
    dbm: false,
    volt: true,
};

export const yAxisWidth = 50;

export const getUnitYAxis = (unit: EUnit, i: number): YAXisOption => ({
    id: unit,
    name: unitLabel[unit],
    type: 'value',
    position: i % 2 ? 'right' : 'left',
    alignTicks: true,
    nameGap: 20,
    offset: Math.floor(i / 2) * yAxisWidth,
    scale: unitScale[unit],
    axisLabel: { fontSize: 12, color: '#ddd' },
    splitLine: { lineStyle: { color: '#222' } },
});

export const getFieldSeries = (device: Device, field: EField, measurements: Measurement[]): SeriesOption => ({
    id: `${device.id}-${field}`,
    yAxisId: fieldUnitMapping[field],
    name: `[${device.display_name[0]}] ${field}`,
    type: 'line',
    showSymbol: false,
    animation: false,
    lineStyle: {
        width: 1,
        color: fieldColorMapping[field],
        type: device.id === 1 ? 'dashed' : 'solid',
    },
    itemStyle: {
        color: '#000',
        borderColor: fieldColorMapping[field],
        borderWidth: 1,
    },
    data: measurements.map(m => [m.time.getTime(), m[field]]),
});

export const baseLegend: LegendComponentOption = {
    textStyle: {
        fontFamily: 'monospace',
        fontSize: 13,
        color: '#eee',
    },
    itemHeight: 8,
    itemWidth: 28,
    lineStyle: { width: 1 },
    itemStyle: { borderWidth: 1 },
};

export const getSeriesLegend = (i: number, device: Device, deviceSeries: SeriesOption[]): LegendComponentOption => ({
    ...baseLegend,
    name: device.display_name,
    data: deviceSeries.map(s => s.name as string),
    top: i * 20,
});
