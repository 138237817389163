import { Checkbox } from 'primereact/checkbox';
import { EField } from '../types/enums';

import type { StateSetter } from '../types/utils';

type Props = {
    fields: Set<EField>;
    setFields: StateSetter<Set<EField>>;
};

export default function FieldSelector({ fields, setFields }: Props) {
    return (
        <div className='flex flex-column gap-2'>
            {Object.values(EField).map(f => (
                <div key={f} className='flex align-items-center'>
                    <Checkbox
                        inputId={`field-${f}`}
                        onChange={e => {
                            setFields(curr => {
                                const newSet = new Set(curr);
                                e.checked ? newSet.add(f) : newSet.delete(f);
                                return newSet;
                            });
                        }}
                        checked={fields.has(f)}
                    />
                    <label htmlFor={`field-${f}`} className='ml-2'>
                        {f}
                    </label>
                </div>
            ))}
        </div>
    );
}
