export const formatNumber = (num: number, leading: number | null = 2, trailing: number | null = 2) => {
    let [integerPart, decimalPart] = num.toString().split('.');
    const finalParts: string[] = [];

    if (leading !== null && integerPart.length < leading) {
        // Ensure the integer part has at least two digits
        while (integerPart.length < 2) {
            integerPart = `0${integerPart}`;
        }
    }

    finalParts.push(integerPart);

    if (!decimalPart) {
        decimalPart = '';
    }

    if (trailing !== null && decimalPart.length < 2) {
        // Ensure the decimal part has at least two digits
        while (decimalPart.length < 2) {
            decimalPart += '0';
        }
    }

    decimalPart.length && finalParts.push(decimalPart);

    // Combine the integer and decimal parts
    return finalParts.join('.');
};
