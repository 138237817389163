import { Checkbox } from 'primereact/checkbox';
import { renderToString } from 'react-dom/server';
import { Fragment } from 'react/jsx-runtime';
import { fieldColorMapping, fieldUnitMapping, sortBy, unitLabel } from './entityUtils';

import type { Device } from '../types/entities';
import type { EField } from '../types/enums';
import type { StateSetter } from '../types/utils';

export const deviceCheckbox = (
    device: Device,
    displayedDevices: Set<number>,
    setDisplayedDevices: StateSetter<Set<number>>,
) => (
    <div key={device.id} className='flex align-items-center'>
        <Checkbox
            inputId={`device-${device.id}`}
            onChange={e => {
                setDisplayedDevices(curr => {
                    const newSet = new Set(curr);
                    e.checked ? newSet.add(device.id) : newSet.delete(device.id);
                    return newSet;
                });
            }}
            checked={displayedDevices.has(device.id)}
        />
        <label htmlFor={`device-${device.id}`} className='ml-2'>
            {device.display_name}
        </label>
    </div>
);

export type TooltipCallbackParams = {
    seriesName: string;
    seriesIndex: number;
    axisValueLabel: string;
    value: [number, number];
};

const lineTemplate = (p: TooltipCallbackParams) => {
    const [deviceName, field] = p.seriesName.split(' ');

    return (
        <Fragment key={p.seriesIndex}>
            <div style={{ display: 'flex', alignItems: 'baseline', gap: '3px' }}>
                <div style={{ color: fieldColorMapping[field as EField], fontFamily: 'monospace' }}>
                    {deviceName === '[i]' ? '---' : <>&mdash;&mdash;&mdash;</>}
                </div>
                <div style={{ fontFamily: 'monospace' }}>{deviceName}</div>
                <div>{field}</div>
            </div>
            <div style={{ textAlign: 'right', paddingLeft: '15px' }}>{p.value[1]}</div>
            <div style={{ textAlign: 'right' }}>{unitLabel[fieldUnitMapping[field as EField]]}</div>
        </Fragment>
    );
};

export const tooltipHtml = (params: TooltipCallbackParams[]) => {
    if (!params.length) {
        return '';
    }

    return renderToString(
        <div style={{ fontFamily: 'arial', color: '#ccc', fontSize: '13px' }}>
            <div style={{ fontSize: '14px', marginBottom: '2px' }}>{params[0].axisValueLabel}</div>
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, auto)', columnGap: '5px' }}>
                {sortBy(params, p => p.seriesIndex.toString()).map(lineTemplate)}
            </div>
        </div>,
    );
};
