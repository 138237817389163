import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { SelectButton } from 'primereact/selectbutton';
import { useEffect, useMemo, useState } from 'react';
import { ETimeUnit, EXRangeType } from '../types/enums';

import type { AbsoluteXRange, RelativeXRange } from '../types/chart';
import type { StateSetter } from '../types/utils';
import type { CalendarBaseProps } from 'primereact/calendar';
import type { SelectItemOptionsType } from 'primereact/selectitem';
import type { Nullable } from 'primereact/ts-helpers';

type Props = {
    rangeType: EXRangeType;
    relativeXRange: RelativeXRange;
    absoluteXRange: AbsoluteXRange;
    setRangeType: StateSetter<EXRangeType>;
    setRelativeXRange: StateSetter<RelativeXRange>;
    setAbsoluteXRange: StateSetter<AbsoluteXRange>;
};

export default function XRangeSelector({
    rangeType,
    relativeXRange,
    absoluteXRange,
    setRangeType,
    setRelativeXRange,
    setAbsoluteXRange,
}: Props) {
    const xRangeTypeOptions: SelectItemOptionsType = useMemo(() => {
        return [
            { value: EXRangeType.relative, label: <i className='pi pi-sign-in' /> },
            { value: EXRangeType.absolute, label: <i className='pi pi-calendar' /> },
        ];
    }, []);

    const relativeUnitOptions: SelectItemOptionsType = useMemo(() => {
        return [
            { value: ETimeUnit.s, label: 'Seconds' },
            { value: ETimeUnit.m, label: 'Minutes' },
            { value: ETimeUnit.h, label: 'Hours' },
            { value: ETimeUnit.d, label: 'Days' },
            { value: ETimeUnit.M, label: 'Months' },
            { value: ETimeUnit.y, label: 'Years' },
        ];
    }, []);

    const [relativeAmount, setRelativeAmount] = useState<number | null>(relativeXRange?.amount ?? null);
    const [relativeUnit, setRelativeUnit] = useState<Nullable<ETimeUnit>>(relativeXRange?.unit);
    const [absoluteStartDate, setAbsoluteStartDate] = useState<Nullable<Date>>(
        absoluteXRange?.startDate ? new Date(absoluteXRange.startDate) : null,
    );
    const [absoluteEndDate, setAbsoluteEndDate] = useState<Nullable<Date>>(
        absoluteXRange?.endDate ? new Date(absoluteXRange.endDate) : null,
    );

    const calendarProps: CalendarBaseProps = useMemo(
        () => ({
            dateFormat: 'dd/mm/yy',
            showTime: true,
            showSeconds: true,
            className: 'w-12rem',
        }),
        [],
    );

    const setXRanges = useMemo(() => {
        return () => {
            if (rangeType === EXRangeType.relative) {
                const amount = relativeAmount ?? 7;
                const unit = relativeUnit ?? ETimeUnit.d;
                setRelativeXRange({ amount, unit });
            } else {
                setAbsoluteXRange({
                    startDate: absoluteStartDate?.toISOString() ?? null,
                    endDate: absoluteEndDate?.toISOString() ?? null,
                });
            }
        };
    }, [rangeType, relativeAmount, relativeUnit, absoluteStartDate, absoluteEndDate]);

    useEffect(() => {
        setXRanges();
    }, [rangeType, relativeUnit]);

    useEffect(() => {
        // debounce
        const timeoutId = setTimeout(() => {
            setXRanges();
        }, 750);
        return () => clearTimeout(timeoutId);
    }, [relativeAmount]);

    return (
        <div className='flex gap-2 align-items-center'>
            <SelectButton
                options={xRangeTypeOptions}
                value={rangeType}
                onChange={e => setRangeType(e.value)}
                allowEmpty={false}
                pt={{ button: { style: { padding: '0.75rem 1rem' } } }}
            />
            {rangeType === EXRangeType.relative ? (
                <>
                    <InputNumber
                        useGrouping={false}
                        value={relativeAmount}
                        onValueChange={e => setRelativeAmount(e.value ?? null)}
                        inputClassName='w-9rem'
                    />
                    <Dropdown
                        value={relativeUnit}
                        onChange={e => setRelativeUnit(e.value)}
                        options={relativeUnitOptions}
                        className='w-9rem'
                        pt={{ wrapper: { style: { maxHeight: 'unset' } } }}
                    />
                </>
            ) : (
                <>
                    <span className='p-float-label'>
                        <Calendar
                            {...calendarProps}
                            value={absoluteStartDate}
                            maxDate={absoluteEndDate ?? undefined}
                            onChange={e => setAbsoluteStartDate(e.value)}
                            onHide={setXRanges}
                        />
                        <label>Début</label>
                    </span>
                    <span className='p-float-label'>
                        <Calendar
                            {...calendarProps}
                            value={absoluteEndDate}
                            minDate={absoluteStartDate ?? undefined}
                            onChange={e => setAbsoluteEndDate(e.value)}
                            onHide={setXRanges}
                        />
                        <label>Fin</label>
                    </span>
                </>
            )}
        </div>
    );
}
