import { timeStr } from '../utils/dateUtils';

type Props = {
    isFetching: boolean;
    fetchedAt: Date | null;
    triggerFetch: () => void;
};

export default function FetchStatus({ isFetching, fetchedAt, triggerFetch }: Props) {
    return (
        <div className='flex align-items-center h-1rem justify-content-end text-end text-500 text-lg'>
            {fetchedAt && (
                <>
                    <span style={{ minWidth: '4.5rem' }}>{timeStr(fetchedAt)}</span>
                    {isFetching ? (
                        <i className='pi pi-spin pi-spinner p-1 mx-1 text-lg' />
                    ) : (
                        <i
                            className='pi pi-sync cursor-pointer p-1 mx-1 hover-primary-color text-lg'
                            onClick={triggerFetch}
                        />
                    )}
                </>
            )}
        </div>
    );
}
