import { EField } from '../types/enums';
import api from './api';

import type { Device, RawMeasurement } from '../types/entities';
import type { CancelToken } from 'axios';

export const getDevices = () => {
    return api.get<Device[]>('devices');
};

export const getDeviceMeasurements = (
    cancelToken: CancelToken,
    device: Device,
    relativexrange?: string,
    from?: string | Date,
    to?: string | Date,
    resolution?: number,
) => {
    return api.get<RawMeasurement[]>(`devices/${device.id}/measurements`, {
        cancelToken,
        params: {
            fields: Object.values(EField).join(','),
            relativexrange,
            from,
            to,
            resolution,
        },
    });
};
